/* Stiluri pentru formular */
.expense-form {
  max-width: 500px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

.expense-form h2 {
  text-align: center;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #555;
}

input[type="number"],
input[type="text"],
input[type="date"],
textarea,
input[type="file"] {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}

textarea {
  resize: vertical;
  height: 100px;
}

button[type="submit"] {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
}

button[type="submit"]:hover {
  background-color: #45a049;
}

input[type="file"] {
  padding: 5px;
}

input[type="number"]:focus,
input[type="text"]:focus,
input[type="date"]:focus,
textarea:focus {
  border-color: #4CAF50;
  outline: none;
}

.form-group input,
.form-group textarea {
  font-size: 16px;
}

.submit-btn {
  font-size: 16px;
}
